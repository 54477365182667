import React, { Component } from 'react';
import qs from 'qs';
import { Tree, message, Pagination, Spin } from 'antd';
import axios from 'axios';
import { json2tree } from './../util/ergodic';
import '../layouts/layout.scss';
import {  LANGUAGE } from '../language';
const { TreeNode } = Tree;
class Product extends Component {
    constructor() {
        super()
        this.state = {
            t_aTC_product: [],
            t_aTC_productType: [],
            t_aTC_productType_y: [],//产品类型原数据
            t_aTC_productTypeDom: null,
            t_aTC_selectProduct: [],
            t_aTC_selectProductobj: null,//选择具体文章内容
            LANG: 'US',
            expandedKeys: [],//展开的树节点
            selectedKeys: [],//选中的节点
            breadcrumb: '',
            t_aI1_current: 1,//page
            t_aI4_articleLoad: false,
        }
        this.itemRender = (current, type, originalElement) => {
            if (type === 'prev') {
                return <a>{LANGUAGE[this.state.LANG].products[4]}</a>;
            }
            if (type === 'next') {
                return <a>{LANGUAGE[this.state.LANG].products[5]}</a>;
            }
            return originalElement;
        }
        this.input = "";
        this.url = {
            hash: '',//地址
            typeid: '',
            articleid: ''
        }
    }
    componentDidMount() {
        this.checkUrl();
        this.getProduct();
        this.getProductType();
        var localLANG = localStorage.getItem('LANG');
        this.setState({
            LANG: localLANG ? localLANG : 'US'
        })
    }
    componentWillReceiveProps(nextProps) {
        // console.log('nextProps',nextProps);
        this.checkUrl();
    }
    checkUrl = () => {
        var url = window.location.hash;
        // console.log('当前url', url);
        var typeindex = url.indexOf('typeid');
        var articleindex = url.indexOf('articleid');
        if (typeindex != -1) {
            var typeid = url.substring(typeindex + 7, url.length);
            this.url.typeid = typeid ? typeid : '';
            // if (typeid) {
            //     this.chooseTypeTree(typeid);
            // }
        }
        if (articleindex != -1) {
            var articleid = url.substring(articleindex + 10, url.length);
            this.url.articleid = articleid ? articleid : '';
            // if (articleid) {
            //     this.chooseArticleTree(articleid);
            // }
        }
        // console.log('this.url', this.url);
    }
    getProduct = () => {
        var that = this;
        let sql = 'select id,title,description,thum_img,product_type from product';
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log('产品', res.data.ret);
                if (res.data.err) { message.warn('无法获取数据'); return }
                that.setState({
                    t_aTC_product: res.data.ret
                })
            })
    }
    getProductType = () => {
        var that = this;
        let sql = 'select * from product_type';
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log('产品类型', sql, res.data);
                if (res.data.err) { message.warn('无法获取数据'); return }
                let f_nowLang = res.data.ret.filter(fil => fil.language === this.state.LANG);
                let menu = json2tree(f_nowLang, 'id', 'parent_id', 'children');
                // console.log(menu);
                that.setState({
                    t_aTC_productType_y: res.data.ret,
                    t_aTC_productType: f_nowLang,
                    t_aTC_productTypeDom: that.loopTree(menu)
                })
                that.checkData = setInterval(() => {
                    if (this.state.t_aTC_product.length > 0) {
                        clearInterval(that.checkData);
                        if (this.url.articleid) {
                            that.chooseArticleTree(this.url.articleid);
                        } else if (this.url.typeid) {
                            that.chooseTypeTree(this.url.typeid);
                        } else {
                            that.defaultChooseTree_v2(menu);
                        }
                    }
                }, 500);
            })
    }
    chooseTypeTree = (typeid) => {
        // console.log('选择产品分类', typeid);
        this.treeSelect([typeid + '']);
        var f_expandedKeys = [];
        // console.log('', this.state.t_aTC_productType_y);
        var alldata = this.state.t_aTC_productType_y;
        (function s(pid) {
            var f_fil = alldata.filter(fil => fil.id == pid);
            // console.log('找',f_fil)
            if (f_fil.length > 0) {
                if (f_fil[0].parent_id) {
                    s(f_fil[0].parent_id)
                }else{//自己就是最顶层节点
                    f_expandedKeys.push(f_fil[0].id+'')
                }
                if (pid !== typeid) { f_expandedKeys.push(f_fil[0].id + '') }
            }
        })(typeid);
        // console.log('找到', f_expandedKeys);
        this.setState({
            expandedKeys: f_expandedKeys
        })
    }
    chooseArticleTree = (articleid) => {
        var that = this;
        // console.log('选择产品文章', articleid);
        this.chooseProduct(articleid);
        var f_expandedKeys = [];
        var alldata = this.state.t_aTC_productType_y;
        (function r(){
            if (that.state.t_aTC_product.length > 0) {
                //查出产品属于哪个节点
                var treeid = that.state.t_aTC_product.filter(fil => fil.id == articleid)[0].product_type;
                (function s(pid) {
                    var f_fil = alldata.filter(fil => fil.id == pid);
                    // console.log('找',f_fil)
                    if (f_fil.length > 0) {

                        if (f_fil[0].parent_id) {
                            s(f_fil[0].parent_id)
                        }
                        if (pid !== articleid) { f_expandedKeys.push(f_fil[0].id + '') }
                    }
                })(treeid);
                // console.log('找到', f_expandedKeys)
                that.setState({
                    expandedKeys: f_expandedKeys
                })
            }
            else{setTimeout(() => {r()}, 500);}
        })();
    }
    defaultChooseTree_v2 = (menu) => {
        var f_child = [];
        var _index = 0;
        var typeid = this.url.typeid;
        if (this.url.typeid) {
            for (let i = 0; i < menu.length; i++) {
                if (menu[i].id == typeid) {
                    f_child = menu[i].children;
                    _index = i;
                }
            }
        } else {
            f_child = menu[0].children;
        }
        if (f_child.length > 0) {
            this.treeSelect([f_child[0].id + '']);
        }
        //父节点所在数组的位置
        this.setState({
            expandedKeys: [menu[_index].id + ''],
        })
    }
    loopTree = (jsonTree) => jsonTree.map(v =>
        <TreeNode key={v.id} title={v.product_type_name} className={!v.parent_id ? 't-father' : ''} >
            {(v.children && v.children.length > 0) ? this.loopTree(v.children) : ''}
        </TreeNode>
    )
    //选中节点
    treeSelect = (e) => {
        // console.log('选中节点', e);
        let key = e.length > 0 ? e[0] : this.state.selectedKeys[0];
        let f_aTC_selectProduct = this.state.t_aTC_product.filter(fil => fil.product_type == key);
        // console.log('过滤文章', f_aTC_selectProduct)
        //判断节点是否为父级节点
        // console.log(this.state.t_aTC_productType);
        var f_fatherType = this.state.t_aTC_productType.filter(fil=>fil.id==key);
        if(f_fatherType.length>0 && f_fatherType[0].children){
            //遍历查找出所有子节点的文章 并聚合
            var keyArr = f_fatherType[0].children.map(v=>v.id);
            var f_selectProduct = this.state.t_aTC_product.filter(fil => keyArr.includes(fil.product_type) );
            f_aTC_selectProduct = f_selectProduct;
        }
        this.setState({
            t_aTC_selectProductobj: null,
            selectedKeys: [key],
            t_aTC_selectProduct: f_aTC_selectProduct.length > 0 ? f_aTC_selectProduct : [],
        })
        this.getBreadcrumb(key);
        // this.getBreadcrumb_v2(key);
    }
    //选择文章
    chooseProduct = id => {
        var that = this;
        let sql = `select * from product where id =${id}`;
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log('单篇产品文章', res.data);
                if (res.data.err) { message.warn('无法获取数据'); return }
                that.setState({
                    t_aTC_selectProductobj: res.data.ret.length > 0 ? res.data.ret[0] : [],
                })
            })
    }
    //获取面包屑
    getBreadcrumb = (typeid) => {
        const { t_aTC_productType_y } = this.state;
        // console.log('获取面包屑',typeid,t_aTC_productType_y);
        var breadcrumb = [];
        (function c(f_typeid) {
            var t_data = t_aTC_productType_y.filter(fil => fil.id == f_typeid);
            if(t_data.length>0){
              breadcrumb.push(t_data[0].product_type_name);
            if (t_data[0].parent_id) { c(t_data[0].parent_id) }  
            }
        })(typeid);
        // console.log('breadcrumb',breadcrumb);
        var _breadcrumb = breadcrumb.reverse().join(' - ');
        this.setState({ breadcrumb: ' - ' + _breadcrumb })
    }
    //获取面包屑 v2
    getBreadcrumb_v2 = (typeid) => {
        // console.log('当前选中的key',typeid,this.state);
    }
    pageOnchange = (e) => {
        this.setState({
            t_aI1_current: e
        })
    }
    //搜索
    handleSearch = () => {
        var keyword = this.input;
        let f_aTC_res = this.state.t_aTC_product.filter(fil => 
            (fil.title&&fil.title.indexOf(keyword) != -1 )
            || 
            (fil.description&&fil.description.indexOf(keyword) != -1)
            );
        // console.log('搜索结果', f_aTC_res);

        this.setState({
            t_aTC_selectProductobj: null,
            t_aTC_selectProduct: f_aTC_res,
            breadcrumb: '-搜索'
        })
    }
    render() {
        const { t_aTC_selectProductobj, t_aTC_selectProduct, t_aI1_current, t_aI4_articleLoad } = this.state;
        return (
            <div className="mianContainer">
                <div className="containerInner">
                    <p>{LANGUAGE[this.state.LANG].products[0]}{this.state.breadcrumb}</p>
                    <hr />
                    <div className="breadNav">
                        <div className="navSearch">
                            <span style={{ fontSize: this.state.LANG == 'US' ? '15px' : '18px' }}>{LANGUAGE[this.state.LANG].products[1]}</span>
                            <input style={{ width: this.state.LANG == 'US' ? '50%' : '60%' }} type="text"
                                placeholder={LANGUAGE[this.state.LANG].products[2]}
                                onChange={e => this.input = e.target.value} />
                            <button onClick={this.handleSearch}></button>
                        </div>
                        <hr />
                        {!this.state.t_aTC_productTypeDom&&<div style={{textAlign:'center'}}><Spin /></div>}
                        <Tree onSelect={this.treeSelect}
                            expandedKeys={this.state.expandedKeys}
                            selectedKeys={this.state.selectedKeys}
                            onExpand={e => this.setState({ expandedKeys: e })}>
                            {this.state.t_aTC_productTypeDom}
                            {/* {this.state.t_aTC_productType.length>0&&this.loopTree(this.state.t_aTC_productType)} */}
                        </Tree>
                    </div>
                    <div className="productMain">
                        {/* 文章列表 */}
                        {/* {t_aI4_articleLoad&&<div style={{textAlign:'center'}}><Spin /></div>} */}
                        {t_aTC_selectProductobj == null && t_aTC_selectProduct.length > 0 ?
                            <div>
                                {
                                    t_aTC_selectProduct.length > 5 ?
                                        t_aTC_selectProduct.slice(t_aI1_current * 5 - 5, t_aI1_current * 5)
                                            .map((v, index) =>
                                                <div key={index} className="list" onClick={e => this.chooseProduct(v.id)}>
                                                    <a ><img src={v.thum_img}></img></a>
                                                    <dl>
                                                        <a><dt>{v.title}</dt></a>
                                                        <dd>{v.description}</dd>
                                                        <a><span>{LANGUAGE[this.state.LANG].products[3]}</span></a>
                                                    </dl>
                                                </div>
                                            )
                                        :
                                        t_aTC_selectProduct.map((v, index) =>
                                            <div key={index} className="list" onClick={e => this.chooseProduct(v.id)}>
                                                <a ><img src={v.thum_img}></img></a>
                                                <dl>
                                                    <a><dt>{v.title}</dt></a>
                                                    <dd>{v.description}</dd>
                                                    <a><span>{LANGUAGE[this.state.LANG].products[3]}</span></a>
                                                </dl>
                                            </div>
                                        )
                                }
                                {/* 列表切换 */}
                                <Pagination
                                    total={t_aTC_selectProduct.length}
                                    current={t_aI1_current}
                                    pageSize={5}
                                    onChange={this.pageOnchange}
                                    itemRender={this.itemRender} />
                            </div> : ''}
                        {/* 文章内容 */}
                        {t_aTC_selectProductobj != null ?
                            <div className="productDetail">
                                <h3>{t_aTC_selectProductobj.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: t_aTC_selectProductobj.content }}></div>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
        )
    }
}
export default Product