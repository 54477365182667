export var LANGUAGE = {
    // LANG:'US',// ZH
    //英文
    'US': {
        header: ['About Us', 'Products', 'Solution', 'News', 'Download', 'Contact'],
        footer: ['Solution', 'Automotive Bonding and Sealing Solution', 'Building Sealing Solution', 'Concrete Road Sealing Solution', 'Waterproof Coating Solution', 'Company', 'About Us', 'News', 'Contact', 'Copyright © 2019-2020 Shanghai Joyfree Co.，Ltd. China All rights reserved'],
        overview: ['PRODUCT CATEGORY', 'AUTOMOTIVE', 'CONSTRUCTION', 'Other Adhesives', 'Applicators / Accessories'],
        company: ['After ten years’ brewing, JOYFREE is pushed out by most advanced adhesive technology from China, the adhesives and sealants are already used and approved by domestic and abroad market for many years, especially South America, North America, Southeast and South Africa etc. Now we are engaged in building our own international brand, welcome to be our agent in the world!', 'For now, we have THREE main products series: FIRST is PU adhesive sealant for car glass bonding and replacement, car body sealing etc.; SECOND is self leveling PU and Acrylic sealant for concrete road joints sealing and building roof coating etc.; THIRD is PU and SILICONE sealant for construction sealing. Besides, we also help supply tools and accessories for application. ', 'JOYFREE —— enJoy the Free cooperation between China and the world.'],
        products: ['Location: Products', 'Product Category', 'search', 'more >>', 'prev', 'next'],
        solution: ['Location: Solution', 'Solution', 'search'],
        download: ['Location: Download - Search', 'Download', 'search', 'Attention:', '· Please search with product name or model no.', '· Any question, contact with info@joy-free.com', 'File List'],
        contacts: ['Shanghai Joyfree Co.,Ltd', 'Contact Us'],
    },
    //中文
    'ZH': {
        header: ['公司简介', '产品', '解决方案', '新闻', '下载中心', '联系我们'],
        footer: ['解决方案', '汽车粘接密封解决方案', '建筑密封解决方案', '道路灌缝解决方案', '防水涂料解决方案', '企业信息', '公司简介', '新闻', '联系我们', 'Copyright © 2019-2020 上海乔斐瑞贸易有限公司 版权所有'],
        overview: ['产品分类', '汽车类', '建筑类', '其他胶类', '工具及配件'],
        company: ['JOYFREE —— 历经10余年的沉淀和酝酿，作为国内先进胶粘剂技术代表联合推出的国际品牌，JOYFREE 的产品均已在国际市场上经过多年的使用和认可，尤其是南美，北美，东南亚，南非等地区。', '现在我们致力于创建属于我们自己的国际品牌，热忱欢迎您成为我们在全球各地的品牌代理商！', 'JOYFREE 目前的主营产品为三大类，第一大类针对汽车的挡风玻璃粘接维修，车身密封等的汽车聚氨酯粘接密封胶；第二大类针对混凝土道路如机场跑道，城市道路缝隙的聚氨酯自流平灌缝胶；第三大类针对建筑屋顶的防水涂层及建筑缝隙的聚氨酯，硅胶等的建筑密封胶。', 'JOYFREE —— enJoy the Free cooperation between China and the world.', 'JOYFREE —— 享受中国同世界的自由合作！'],
        products: ['当前位置：产品', '产品分类', '搜索', '更多 >>', '上一页', '下一页'],
        solution: ['当前位置：解决方案', '解决方案', '搜索'],
        download: ['当前位置：资料下载 - 搜索', '资料下载', '搜索', '注：', '· 请使用该产品的名称或者型号搜索TDS和MSDS。', '· 如果搜索产品目标，可以使用“汽车产品目标”或“电子产品目录”等关键词搜索。', '资料列表'],
        contacts: ['上海乔斐瑞贸易有限公司', '总部信息'],
    }
}