/**
 * xgq 2019.8.14
 * @param  {*原始数据 Array} data
 * @param {*子id Number} childId 
 * @param {*父id Number} parentId 
 * @param {*子属性名 String} childName 
 */
export var json2tree = (data, childId, parentId, childName) => {
    try {
        var res = [], hash = {}, i = 0, j = 0, len = data.length;
        for (; i < len; i++) {
            hash[data[i][childId]] = data[i];
        }
        for (; j < len; j++) {
            var data_obj = data[j], hashChild = hash[data_obj[parentId]];
            if (hashChild) {
                if (!hashChild[childName]) { hashChild[childName] = [] };
                hashChild[childName].push(data_obj);
            } else {
                res.push(data_obj);
            }
        }
        return res;
    } catch (err) {
        return err;
    }
}


/**
 * 递归 Tree 示例
 */
// loopTree = (jsonTree) => {
//     jsonTree.map(v =>
//         <TreeNode key={v.id} title={v.product_type_name}>
//             {(v.children && v.children.length > 0) ? this.loopTree(v.children) : ''}
//         </TreeNode>
//     )
// }