import React from 'react';
// Style
import '../layouts/layout.scss'
// SVG
import Logo from '../assets/Logo.svg';
import Chinese from '../assets/Chinese.svg';
import English from '../assets/English.svg';
// Router
import { Link } from 'react-router-dom';
//language
import { LANGUAGE } from '../language';
// import { blockParams } from 'handlebars';

class Header extends React.Component {
    constructor() {
        super()
        this.state = {
            LANG: 'US',//默认US
            menuSelect: 1,//当前选中的菜单
        }
        this.menu = {
            '/company': 1,
            '/product': 2,
            '/solution': 3,
            '/news': 4,
            '/download': 5,
            '/contacts': 6
        }
    }
    componentDidMount() {
        var localLANG = localStorage.getItem('LANG');
        //获取当前url
        var nowPash = window.location.href;
        // var aa= indexOf('#');debugger
        var p_name = nowPash.substring(nowPash.indexOf('#') + 1, nowPash.length);
        this.setState({
            LANG: localLANG ? localLANG : 'US',
            menuSelect: this.menu[p_name]
        })

    }
    changeLang = (lang) => {
        localStorage.setItem('LANG', lang);
        //刷新页面
        window.location.reload();
    }
    changeMenu = (id) => {
        this.setState({
            menuSelect: id
        })
    }
    render() {
        const { LANG, menuSelect } = this.state;
        return (
            <div>
                <div className={LANG === 'US' ? 'top us-font' : 'top'}>
                    <div className="topNavBar">
                        <div className="logoAndMenu">
                            <Link to="/main/overview"><img src={Logo} className="joyfreeLogo" alt="Company logo" /></Link>
                            <ul>
                                <li className={menuSelect === 1 ? '_select' : ''} onClick={e => this.changeMenu(1)}><Link to="/main/company">{LANGUAGE[this.state.LANG].header[0]}</Link></li>
                                <li className={menuSelect === 2 ? '_select' : ''} onClick={e => this.changeMenu(2)}><Link to="/main/product">{LANGUAGE[this.state.LANG].header[1]}</Link></li>
                                <li className={menuSelect === 3 ? '_select' : ''} onClick={e => this.changeMenu(3)}><Link to="/main/solution">{LANGUAGE[this.state.LANG].header[2]}</Link></li>
                                <li className={menuSelect === 4 ? '_select' : ''} onClick={e => this.changeMenu(4)}><Link to="/main/news">{LANGUAGE[this.state.LANG].header[3]}</Link></li>
                                <li className={menuSelect === 5 ? '_select' : ''} onClick={e => this.changeMenu(5)}><Link to="/main/download">{LANGUAGE[this.state.LANG].header[4]}</Link></li>
                                <li className={menuSelect === 6 ? '_select' : ''} onClick={e => this.changeMenu(6)}><Link to="/main/contacts">{LANGUAGE[this.state.LANG].header[5]}</Link></li>
                            </ul>
                        </div>
                        <div className="conversionPanel">
                            <div className="zh" onClick={e => this.changeLang('ZH')}><img src={Chinese} alt="conversion Chinese" />中文</div>
                            <span>|</span>
                            <div className="us" onClick={e => this.changeLang('US')}><img src={English} alt="conversion English" />English</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header