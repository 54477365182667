import React from 'react'
import '../layouts/layout.scss'
import Chat from '../assets/chat.svg'
import Message from '../assets/message.svg'

class FloatBall extends React.Component {
    constructor() {
        super()
        this.state = {
            LANG: 'US',//默认US
        }
    }
    componentWillMount() {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?231556b06362f8d0ff0ca5cf2ef361b8";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    }
    component
    render() {
        return (
            <div className="floatBall">
            </div >
        )
    }
}

export default FloatBall