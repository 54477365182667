import React, { Component } from 'react';
import '../layouts/swiper.css'
import Swiper from 'swiper'
import qs from 'qs';
import axios from 'axios';
import { message } from 'antd';
class CustomBuildSwiper extends Component {
    constructor() {
        super()
        this.state = {
            t_aTC_banner: []
        }
    }
    componentDidMount() {
        var localLANG = localStorage.getItem('LANG');
        this.getOverviewImg(localLANG ? localLANG : 'US');
    }
    dedaultSwiper = () => {
        var mySwiper = new Swiper('.swiper-container', {
            autoplay: true, // 循环模式选项
            // 如果需要分页器
            pagination: {
                el: '.swiper-pagination',
            },
            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            loop: true
        })
    }
    getOverviewImg = (localLANG) => {
        let sql = `select * from overview_img where language='${localLANG}'`;
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                if (res.data.err) { message.warn('无数据'); return }
                this.setState({
                    t_aTC_banner: res.data.ret
                })
                this.dedaultSwiper();//获得图片之后初始化轮播图
            })
    }
    render() {
        return (
            <div className="swiper-container" >
                <div className="swiper-wrapper">
                    {this.state.t_aTC_banner.map(img =>
                        // <a key={img.id} target="_blank" href={img.link || '#'} className="swiper-slide">
                        //     <div className="banner-img" style={{ backgroundImage: 'url(' + img.url + ')' }} />
                        // </a>
                        <a key={img.id} className="swiper-slide" onClick={e=>img.link&&window.open(img.link)}>
                            <div className="banner-img" style={{ backgroundImage: 'url(' + img.url + ')' }} />
                        </a>
                    )}
                </div>
                {/* 分页器 */}
                <div className="swiper-pagination"></div>
                {/* 切换按钮 */}
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </div>
        )
    }
}


export default CustomBuildSwiper;
