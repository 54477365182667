import React from 'react';
import '../layouts/layout.scss';
import qs from 'qs';
import { message, Pagination, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { LANGUAGE } from '../language';
class News extends React.Component {
    constructor() {
        super()
        this.state = {
            t_aTC_news: [],
            t_aI1_index: 1,
            LANG: 'US'
        }
        this.url = {}
        this.itemRender = (current, type, originalElement) => {
            if (type === 'prev') {
                return <a>{LANGUAGE[this.state.LANG].products[4]}</a>;
            }
            if (type === 'next') {
                return <a>{LANGUAGE[this.state.LANG].products[5]}</a>;
            }
            return originalElement;
        }
    }
    componentDidMount() {
        var localLANG = localStorage.getItem('LANG');
        this.setState({
            LANG: localLANG ? localLANG : 'US'
        })
        this.checkUrl();
        this.getNews(localLANG ? localLANG : 'US');
    }
    componentWillReceiveProps(nextProps) {
        // console.log('nextProps',nextProps);
        this.checkUrl();
    }
    checkUrl = () => {
        var url = window.location.hash;
        // console.log('当前url', url);
        var typeindex = url.indexOf('typeid');
        var articleindex = url.indexOf('articleid');
        // if (typeindex != -1) {
        //     var typeid = url.substring(typeindex + 7, url.length);
        //     this.url.typeid = typeid ? typeid : '';
        //     if (typeid) {
        //         this.chooseTypeTree(typeid);
        //     }
        // }
        if (articleindex != -1) {
            var articleid = url.substring(articleindex + 10, url.length);
            this.url.articleid = articleid ? articleid : '';
            if (articleid) {
                this.chooseArticleTree(articleid);
            }
        }
        // console.log('this.url', this.url);
    }
    chooseArticleTree = (articleid) => {
        var that = this;
        // console.log('选择产品文章', articleid);
        that.checkData4 = setInterval(() => {
            if (this.state.t_aTC_news.length > 0) {
                clearInterval(that.checkData4);
                for (let i = 0; i < this.state.t_aTC_news.length; i++) {
                    if (this.state.t_aTC_news[i].id == articleid) {
                        that.setState({ t_aI1_index: i + 1 })
                        return
                    }
                }
            }
        }, 500)

    }
    getNews = (localLANG) => {
        var that = this;
        let sql = `select * from news where language='${localLANG}' order by create_time desc`;
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log(res.data);
                if (res.data.err) { message.warn('无法获取数据'); return }
                that.setState({
                    t_aTC_news: res.data.ret
                })
            })
    }
    pageOnchange = (e) => {
        this.setState({
            t_aI1_index: e
        })
    }
    itemRender = (current, type, originalElement) => {
        if (type === 'prev') { return <a>上一页</a>; }
        if (type === 'next') { return <a>下一页</a>; }
        return originalElement;
    }
    render() {
        const { t_aTC_news, t_aI1_index } = this.state;
        return (
            <div className="newsPages">
                {t_aTC_news.length > 0 ? <article>
                    <strong>{t_aTC_news[t_aI1_index - 1].title}</strong><time>{moment(t_aTC_news[t_aI1_index - 1].update_time).format('YYYY-MM-DD')}</time>
                    <p dangerouslySetInnerHTML={{ __html: t_aTC_news[t_aI1_index - 1].content }}></p>
                </article> : <div style={{ textAlign: 'center' }}><Spin /></div>}
                <Pagination current={t_aI1_index} pageSize={1} total={t_aTC_news.length} itemRender={this.itemRender} onChange={this.pageOnchange} />
            </div>
        );
    }
}

export default News;