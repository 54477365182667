import React, { Component } from 'react';
import {  HashRouter, Route, Redirect, Switch } from 'react-router-dom';
// 导入页
import Header from './components/Header' //导航栏
import Bottom from './components/Bottom' //底部
import Overview from './pages/overview'  //首页
import Company from './pages/company'   //公司介绍
import Product from './pages/product';//产品
import Solution from './pages/solution';//解决方案
import News from './pages/news' //新闻
import Download from './pages/download' //下载
import Contacts from './pages/contacts' //联系我们
import MobileNav from './components/MobileNav' // 移动端样式

//路由配置数组
const RouteList = [
    { path: "/overview", component: Overview },
    { path: "/company", component: Company },
    { path: "/product", component: Product },
    { path: "/solution", component: Solution },
    { path: "/contacts", component: Contacts },
    { path: "/news", component: News },
    { path: "/download", component: Download }
]

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        // console.log('main',this.props);
        return (
            <div>
                <Header {...this.props} />
                <MobileNav {...this.props} />
                <main>
                    {this.props.children}
                </main>
                <Bottom />
            </div>
        )
    }
}
class AppRoute extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/" render={props => <Redirect to="/main/overview" />} />
                    <Route path="/main" render={props =>
                        <Main {...props}>
                            {/* <Switch> */}
                            {
                                RouteList.map(v =>
                                    <Route path={`${props.match.url}${v.path}`} component={v.component} key={v.path} />
                                )
                            }
                            {/* </Switch> */}
                        </Main>
                    } />
                </Switch>
            </HashRouter>
        )
    }
}
export default AppRoute


