import React from 'react'
import '../layouts/layout.scss'
import '../layouts/phone.scss'
import { LANGUAGE } from '../language'

class Company extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            LANG: 'US'
        }
    }
    componentDidMount() {
        var localLANG = localStorage.getItem('LANG');
        this.setState({
            LANG: localLANG ? localLANG : 'US'
        })
    }

    render() {
        return (
            <div className="aboutUs">
                <div className="banner" />
                <div className="paper">
                    <div>
                        {LANGUAGE[this.state.LANG].company[0]}
                        <br />
                        <br />
                        {LANGUAGE[this.state.LANG].company[1]}
                        <br />
                        <br />
                        {LANGUAGE[this.state.LANG].company[2]}
                        <br />
                        <br />
                        {LANGUAGE[this.state.LANG].company[3]}
                        <br />
                        <br />
                        {LANGUAGE[this.state.LANG].company[4]}
                    </div>
                </div>
            </div>
        )
    }
}
export default Company