import React, { Component } from 'react';
import qs from 'qs';
import { Tree, message, Spin } from 'antd';
import axios from 'axios';
// import { json2tree } from './../util/ergodic';
import '../layouts/layout.scss'
import { LANGUAGE } from '../language'

const { TreeNode } = Tree;

class Solution extends Component {
    constructor() {
        super()
        this.state = {
            t_aTC_solution: [],
            t_aTC_solutionType: [],
            t_aI1_selectSolution: {},//选择的方案
            LANG: 'US',
            expandedKeys: [],//展开的树节点
            selectedKeys: [],//选中的节点
            breadcrumb: '',
        }
        //url传参
        this.url = {
        }
        //搜索input
        this.input = '';
    }
    componentDidMount() {
        var localLANG = localStorage.getItem('LANG');
        this.checkUrl();
        this.getSolution(localLANG ? localLANG : 'US');
        this.getSolutionType(localLANG ? localLANG : 'US');
        
        this.setState({
            LANG: localLANG ? localLANG : 'US'
        })
    }
    componentWillReceiveProps(nextProps) {
        // console.log('nextProps',nextProps);
        this.checkUrl();
    }
    checkUrl = () => {
        var url = window.location.hash;
        // console.log('当前url', url);
        var typeindex = url.indexOf('typeid');
        var articleindex = url.indexOf('articleid');
        var aotuindex = url.indexOf('auto');
        if (typeindex != -1) {
            var typeid = url.substring(typeindex + 7, url.length);
            this.url.typeid = typeid ? typeid : '';
            // if (typeid) {
            //     this.chooseTypeTree(typeid);
            // }
        }
        if (articleindex != -1) {
            var articleid = url.substring(articleindex + 10, url.length);
            this.url.articleid = articleid ? articleid : '';
            // if (articleid) {
            //     this.chooseArticleTree(articleid);
            // }
        }
        if(aotuindex != -1){
            //
        }
        // console.log('this.url', this.url);
    }
    chooseTypeTree = (typeid) => {
        var that = this;
        // console.log('选择解决方案分类', typeid);
        var f_expandedKeys = [];
        (function r() {
            if (that.state.t_aTC_solutionType.length > 0) {
                var f_data = that.state.t_aTC_solutionType.filter(fil => fil.id == typeid);
                if (f_data.length > 0) {
                    f_expandedKeys.push('fu-' + f_data[0].id);
                    that.chooseFirstArticle(f_data[0].id);
                }
                // console.log('ok',f_expandedKeys)
                that.setState({
                    expandedKeys: f_expandedKeys
                })
            }
            else { setTimeout(() => { r() }, 500); }
        })();
    }
    //根据tree节点 展示第一篇文章
    chooseFirstArticle = (parentTreeId)=>{
        var f_solution = this.state.t_aTC_solution.filter(fil => fil.id == parentTreeId);
        if(f_solution.length>0){
            var typeid = f_solution[0].id
            this.treeSelect(['zi-' + typeid]);
        }
    }
    chooseArticleTree = (articleid) => {
        var that = this;
        // console.log('选择解决方案文章', articleid);
        this.treeSelect(['zi-' + articleid]);
        (function r() {
            if (that.state.t_aTC_solution.length > 0) {
                var f_typeid = that.state.t_aTC_solution.filter(fil => fil.id == articleid)[0].solution_type;
                // console.log('okk',f_typeid)
                that.setState({
                    expandedKeys: ['fu-' + f_typeid]
                })
            }
            else { setTimeout(() => { r() }, 500); }
        })();
    }
    getSolution = () => {
        var that = this;
        let sql = `select id,title,description,thum_img,solution_type from solution`;
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log('solution', sql, res.data);
                if (res.data.err) { message.warn('无法获取数据'); return }
                let data = res.data.ret;
                that.setState({
                    t_aTC_solution: data,
                })
            })
    }
    getSolutionType = (localLANG) => {
        var that = this;
        let sql = `select * from solution_type where language='${localLANG}'`;
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log('搜索', sql, res.data);
                if (res.data.err) { message.warn('无法获取数据'); return }
                that.setState({
                    t_aTC_solutionType: res.data.ret,
                });
                // setTimeout(() => {
                //     if (that.url.articleid) {
                //         that.chooseArticleTree(that.url.articleid);
                //     } else if (that.url.typeid) {
                //         that.chooseTypeTree(that.url.typeid);
                //     } else {
                //         that.defaultChooseTree(res.data.ret[0].id);
                //     }

                // }, 0);
                // 有数据再执行
                (function r() {
                    if (that.state.t_aTC_solution.length > 0) {
                        if (that.url.articleid) {
                            that.chooseArticleTree(that.url.articleid);
                        } else if (that.url.typeid) {
                            that.chooseTypeTree(that.url.typeid);
                        } else {
                            that.defaultChooseTree(res.data.ret[0].id);
                        }
                    }
                    else { setTimeout(() => { r() }, 500); }
                })();
            })
    }
    defaultChooseTree = (typeid) => {
        let f_data = this.state.t_aTC_solution.filter(fil => fil.solution_type == typeid);
        this.setState({
            expandedKeys: ['fu-' + typeid]
        })
        if (f_data.length > 0) {
            this.treeSelect(['zi-' + f_data[0].id])
        }

    }
    treeSelect = (e) => {
        var that = this;
        let key = e.length > 0 ? e[0] : this.state.selectedKeys[0];
        // console.log('选中树节点', e);
        let _arr = key.split('-');
        if (_arr[0] === 'zi') {
            let id = Number(_arr[1]);
            let sql = `select * from solution where id =${id}`;
            axios.post('http://47.103.197.21:8081/mysql',
                qs.stringify({ 'sql': sql }))
                .then(res => {
                    // console.log('单篇解决方案', res.data);
                    if (res.data.err) { message.warn('无法获取数据'); return }
                    that.setState({
                        t_aI1_selectSolution: res.data.ret.length > 0 ? res.data.ret[0] : [],
                        selectedKeys: [key],
                    })
                })
            // let f_solution = this.state.t_aTC_solution.filter(fil => fil.id == key[1]);
            // this.setState({
            //     t_aI1_selectSolution: f_solution[0],
            //     selectedKeys: e,
            //     breadcrumb:f_solution[0].title
            // });
        }
        this.getBreadcrumb(key);
    }
    //获取面包屑
    getBreadcrumb = (treeKey) => {
        if(!treeKey)return;
        let _arr = treeKey.split('-');
        const { t_aTC_solution, t_aTC_solutionType } = this.state;
        var breadcrumb = '';
        if (_arr[0] == 'fu') {
            var f_data = t_aTC_solutionType.filter(fil => fil.id == _arr[1]);
            if (f_data.length == 0) return
            breadcrumb = (f_data[0].solution_type_name)
        }
        else if (_arr[0] == 'zi') {
            var f_zdata = t_aTC_solution.filter(fil => fil.id == _arr[1]);
            if (f_zdata.length == 0) return
            var f_data = t_aTC_solutionType.filter(fil => fil.id == f_zdata[0].solution_type);
            if (f_data.length == 0) return
            breadcrumb = (f_data[0].solution_type_name)
        }
        this.setState({
            breadcrumb: ' - ' + breadcrumb
        })
        // console.log('面包屑',breadcrumb);
    }
    //搜索
    handleSearch = () => {
        var keyword = this.input;
        let f_aTC_res = this.state.t_aTC_solution.filter(fil =>
            (fil.title && fil.title.indexOf(keyword) != -1)
            ||
            (fil.description && fil.description.indexOf(keyword) != -1)
        );
        // console.log('搜索结果', f_aTC_res);

        this.setState({
            t_aTC_selectProduct: f_aTC_res,
            breadcrumb: '-搜索'
        })
    }
    render() {
        return (
            <div className="mianContainer">
                <div className="containerInner">
                    <p>
                        {LANGUAGE[this.state.LANG].solution[0]}
                        {/* {this.state.t_aI1_selectSolution.title} */}
                        {this.state.breadcrumb}
                    </p>
                    <hr />
                    <div className="breadNav">
                        <div className="navSearch">
                            <span style={{ fontSize: this.state.LANG == 'US' ? '20px' : '18px' }}>{LANGUAGE[this.state.LANG].solution[1]}</span>
                            {/* <input style={{ width: this.state.LANG == 'US' ? '50%' : '60%' }} type="text"
                                placeholder={LANGUAGE[this.state.LANG].solution[2]}
                                onChange={e => this.input = e.target.value} />
                            <button onClick={this.handleSearch}></button> */}
                        </div>
                        <hr />
                        {this.state.t_aTC_solutionType.length == 0 && <div style={{ textAlign: 'center' }}><Spin /></div>}
                        <Tree onSelect={this.treeSelect}
                            expandedKeys={this.state.expandedKeys}
                            selectedKeys={this.state.selectedKeys}
                            onExpand={e => this.setState({ expandedKeys: e })}>
                            >
                            {this.state.t_aTC_solutionType.filter(lang => lang.language === this.state.LANG).map((v, index) =>
                                <TreeNode key={'fu-' + v.id} title={v.solution_type_name} className="t-father">
                                    {this.state.t_aTC_solution.filter(fil => fil.solution_type == v.id).map(v2 =>
                                        <TreeNode key={'zi-' + v2.id} title={v2.title} />
                                    )}
                                    {/* <TreeNode /> */}
                                </TreeNode>
                            )}
                        </Tree>
                    </div>
                    <div className="solutionMain">
                        <div dangerouslySetInnerHTML={{ __html: this.state.t_aI1_selectSolution.content }}></div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Solution