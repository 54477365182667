import React, { Component } from 'react';
import '../layouts/phone.scss';
import '../layouts/layout.scss';
// 资源
import Logo from '../assets/Logo.svg';
import NavSearch from '../assets/navSearch.svg';
import Menu from '../assets/menu.svg';
import Close from '../assets/close.svg';
import Chinese from '../assets/Chinese.svg';
import English from '../assets/English.svg';

import qs from 'qs';
import { Tree, Spin } from 'antd';
import axios from 'axios';
import { json2tree } from './../util/ergodic';
import { LANGUAGE } from '../language';
import { Link } from 'react-router-dom';
import { message } from 'antd';

const { TreeNode } = Tree;
class MobileNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LANG: 'US',
            isToggleOn: false,
            selectTab: 'CLOSE',//MENU SEARCH CLOSE
            t_dom_tree: null,//tree dom
            //data
            t_productType: [],
            t_solutionType: [],
            t_materialType: [],
            t_product: [],
            t_solution: [],
            t_material: [],
            t_news: [],
            t_aTC_result: [],//搜索结果
            resultList: false
        };
        this.Server = "http://47.103.197.21:8081/mysql";
        this.selectKey = [];//选中的tree
        this.form = {
            input: '',
        }
    }
    componentDidMount() {
        var localLANG = localStorage.getItem('LANG');
        this.setState({
            LANG: localLANG ? localLANG : 'US',
        })
        this.getAllData(localLANG ? localLANG : 'US');
    }
    changeLang = (lang) => {
        localStorage.setItem('LANG', lang);
        //刷新页面
        window.location.reload();
    }
    inputChange = (e) => {
        this.form.input = e.target.value;
    }
    handleSearch = () => {
        // console.log('搜索');
        const { t_product, t_solution, t_material, t_news } = this.state;
        var f_keyword = this.form.input;
        if (!f_keyword) { message.warn(this.state.LANG === 'US' ? 'No results, try again after editing.' : '无结果，请编辑后重试！'); return };
        var func_fil = (fil) => fil.title.indexOf(f_keyword) !== -1;
        var product_res = t_product.filter(fil => func_fil(fil)).map(v => { return { name: v.title, link: '/main/product?articleid=' + v.id } });
        var solution_res = t_solution.filter(fil => func_fil(fil)).map(v => { return { name: v.title, link: '/main/solution?articleid=' + v.id } });
        var material_res = t_material.filter(fil => fil.material_name.indexOf(f_keyword) !== -1).map(v => { return { name: v.material_name, link: '/main/download' } });;
        var news_res = t_news.filter(fil => func_fil(fil)).map(v => { return { name: v.title, link: '/main/solution?articleid=' + v.id } });
        var f_res = [...product_res, ...solution_res, ...material_res, ...news_res];

        this.setState({
            t_aTC_result: f_res,
            resultList: true
        })
    }
    getAllData = (localLANG) => {
        var that = this;
        var f_productType = [];
        var f_solutionType = [];
        var f_materialType = [];
        var f_product = [];
        var f_solution = [];
        var f_material = [];
        var f_news = [];
        let sql1 = `select * from product_type where language='${localLANG}'`;
        let sql2 = `select * from solution_type where language='${localLANG}'`;
        let sql3 = `select * from material_type where language='${localLANG}'`;
        let sql4 = `select id,title,description,thum_img,product_type from product`;
        let sql5 = `select id,title,description,thum_img,solution_type from solution`;
        let sql6 = `select * from material`;
        let sql7 = `select * from news where language='${localLANG}'`;
        axios.post(this.Server, qs.stringify({ 'sql': sql1 }))
            .then(res => {
                // console.log('产品类型', res.data);
                f_productType = json2tree(res.data.ret, 'id', 'parent_id', 'children');
            })
        axios.post(this.Server, qs.stringify({ 'sql': sql2 }))
            .then(res => {
                // console.log('方案类型', res.data);
                f_solutionType = json2tree(res.data.ret, 'id', 'parent_id', 'children');
            })
        axios.post(this.Server, qs.stringify({ 'sql': sql3 }))
            .then(res => {
                // console.log('文件类型', res.data);
                f_materialType = res.data.ret;
            })
        axios.post(this.Server, qs.stringify({ 'sql': sql4 }))
            .then(res => {
                // console.log('产品', res.data);
                f_product = res.data.ret;
            })
        axios.post(this.Server, qs.stringify({ 'sql': sql5 }))
            .then(res => {
                // console.log('方案', res.data);
                f_solution = res.data.ret;
            })
        axios.post(this.Server, qs.stringify({ 'sql': sql6 }))
            .then(res => {
                // console.log('文件', res.data);
                f_material = res.data.ret;
            })
        axios.post(this.Server, qs.stringify({ 'sql': sql7 }))
            .then(res => {
                // console.log('新闻', res.data);
                f_news = res.data.ret;
            })
        that.checkData = setInterval(() => {
            if (f_productType.length > 0 &&
                f_solutionType.length > 0 &&
                f_materialType.length > 0 &&
                f_product.length > 0 &&
                f_solution.length > 0 &&
                f_material.length > 0 &&
                f_news.length > 0
            ) {
                clearInterval(that.checkData);
                that.createMenu(f_productType, f_solutionType, f_materialType, f_product, f_solution, f_material, f_news);
                that.setState({
                    t_productType: f_productType,
                    t_solutionType: f_solutionType,
                    t_materialType: f_materialType,
                    t_product: f_product,
                    t_solution: f_solution,
                    t_material: f_material,
                    t_news: f_news
                })
            }
        }, 500);
    }
    createMenu = (f_productType, f_solutionType, f_materialType, f_product, f_solution, f_material, f_news) => {
        var f_dom_tree = <Tree onSelect={this.treeOnSelect}>
            <TreeNode key="a-company" className="f-tab" title={LANGUAGE[this.state.LANG].header[0]}></TreeNode>
            <TreeNode key="a-product" className="f-tab" title={LANGUAGE[this.state.LANG].header[1]}>
                {this.loopProduct(f_productType, f_product)}
            </TreeNode>
            <TreeNode key="a-solution" className="f-tab" title={LANGUAGE[this.state.LANG].header[2]}>
                {this.loopSolution(f_solutionType, f_solution)}
            </TreeNode>
            <TreeNode key="a-news" className="f-tab" title={LANGUAGE[this.state.LANG].header[3]}>
                {f_news.map(news => <TreeNode key={'nn-' + news.id} title={news.title} />)}
            </TreeNode>
            <TreeNode key="a-download" className="f-tab" title={LANGUAGE[this.state.LANG].header[4]}>
                {this.loopMaterial(f_materialType, f_material)}
            </TreeNode>
            <TreeNode key="a-contacts" className="f-tab" title={LANGUAGE[this.state.LANG].header[5]}></TreeNode>
        </Tree>;
        this.setState({
            t_dom_tree: f_dom_tree
        })
    }
    treeOnSelect = (e) => {
        // var key = e.length > 0 ? e[0] : this.selectKey;
        if (e.length === 0) { return }
        var key = e[0];
        // console.log(key);
        var _arr = key.split('-');
        // console.log(_arr);
        switch (_arr[0]) {
            //a:第一等级 页面菜单
            case 'a': this.props.history.push('/main/' + _arr[1]);
                break;
            //p:产品页 分类id
            case 'p': this.props.history.push('/main/product?typeid=' + _arr[1]);
                break;
            //pp:产品页 文章id
            case 'pp': this.props.history.push('/main/product?articleid=' + _arr[1]);
                break;
            //解决方案
            // case 's' : this.props.history.push('/main/solution?article=' + _arr[1]);
            // break;
            case 'ss': this.props.history.push('/main/solution?articleid=' + _arr[1]);
                break;
            //新闻
            case 'nn': this.props.history.push('/main/news?articleid=' + _arr[1]);
                break;
            //下载
            case 'm': this.props.history.push('/main/download?typeid=' + _arr[1]);
                break;
        }
        if (
            _arr[0] === 'a' ||
            _arr[0] === 'p' ||
            _arr[0] === 'pp' ||
            _arr[0] === 'ss' ||
            _arr[0] === 'nn' ||
            _arr[0] === 'm'
        ) {
            this.setState({
                selectTab: 'CLOSE'
            })
        }
    }
    loopProduct = (jsonTree, data) => jsonTree.map(v =>
        <TreeNode key={v.children ? 'pf-' + v.id : 'p-' + v.id} title={v.product_type_name} className={!v.parent_id ? 't-father' : ''} >
            {(v.children && v.children.length > 0) ?
                this.loopProduct(v.children, data) :
                data.filter(fil => fil.product_type === v.id).map(c => <TreeNode key={'pp-' + c.id} title={`- ${c.title}`} />)
            }
        </TreeNode>
    )
    loopSolution = (jsonTree, data) => jsonTree.map(v =>
        <TreeNode key={v.children ? 'sf-' + v.id : 's-' + v.id} title={v.solution_type_name} className={!v.parent_id ? 't-father' : ''} >
            {(v.children && v.children.length > 0) ?
                this.loopSolution(v.children) :
                data.filter(fil => fil.solution_type === v.id).map(c => <TreeNode key={'ss-' + c.id} title={`- ${c.title}`} />)
            }
        </TreeNode>
    )
    loopMaterial = (jsonTree, data) => jsonTree.map(v =>
        <TreeNode key={v.children ? 'mf-' + v.id : 'm-' + v.id} title={v.material_type_name} className={!v.parent_id ? 't-father' : ''} >
            {(v.children && v.children.length > 0) ?
                this.loopMaterial(v.children) : ''
                // data.filter(fil => fil.solution_type == v.id).map(c => <TreeNode key={'mm-'+c.id} title={`- ${c.title}`} />)
            }
        </TreeNode>
    )
    render() {
        return (
            <div>
                <div className="mobileNav">
                    <a href="#"><img src={Logo} alt="Company logo" /></a>
                    <div className="buttons">
                        <img src={NavSearch} alt="NavSearch" onClick={e => this.setState({ selectTab: 'SEARCH' })} />
                        <img src={Menu} alt="Menu" onClick={e => this.setState({ selectTab: 'MENU' })} />
                    </div>

                </div>
                <div className="placeholder"></div>
                {this.state.selectTab === 'MENU' ?
                    <div className="mobile-menu">
                        <div className="menu-item">
                            <div className="item-header">
                                <div className="switchBox">
                                    <div className={this.state.LANG === 'ZH' ? 'chinese _choose' : 'chinese'} onClick={e => this.changeLang('ZH')}>
                                        <img src={Chinese} alt="conversion Chinese" />中文
                                        </div>
                                    <div className={this.state.LANG === 'US' ? 'english _choose' : 'english'} onClick={e => this.changeLang('US')}>
                                        <img src={English} alt="conversion English" />English
                                        </div>
                                </div>
                                <div className="switchButton">
                                    <img src={NavSearch} onClick={e => this.setState({ selectTab: 'SEARCH' })} alt="search button" />
                                    <img src={Close} onClick={e => this.setState({ selectTab: 'CLOSE' })} alt="close menu" />
                                </div>
                            </div>
                            <hr />
                            {!this.state.t_dom_tree && <div><Spin /></div>}
                            <div>{this.state.t_dom_tree}</div>
                        </div>
                    </div> : ''}
                {this.state.selectTab === 'SEARCH' ?
                    <div className="mobile-search">
                        <div className="box-shadow">
                            <div className="search-header">
                                <div className="switchButton">
                                    <img src={Close} onClick={e => this.setState({ selectTab: 'CLOSE' })} alt="close menu" />
                                    <img src={Menu} onClick={e => this.setState({ selectTab: 'MENU' })} alt="search button" />
                                </div>
                            </div>
                            <div className="search-ipt">
                                <input className="_search" type="text" onInput={this.inputChange} placeholder={this.state.LANG === 'US' ? 'Search' : '搜索'} />
                                <img className="_btn" src={NavSearch} alt="searchBtn" onClick={this.handleSearch} />
                            </div>
                            {this.state.resultList === true ?
                                <div className="resultList">
                                    <ul>
                                        {this.state.t_aTC_result.map((v) => {
                                            return <li onClick={e => this.setState({ selectTab: 'CLOSE', t_aTC_result: [], resultList: false })}><Link to={v.link}>{v.name}</Link></li>
                                        })}
                                    </ul>
                                </div>
                                : ''}
                        </div>
                    </div> : ''}
            </div>
        );
    }
}

export default MobileNav;