import React from 'react'
import qs from 'qs'
import axios from 'axios'
import '../layouts/layout.scss'
import { Tree, message ,Spin} from 'antd'
import { LANGUAGE } from '../language';

const { TreeNode } = Tree;
class Download extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            t_aTC_materialType: [],//所有资料类型
            t_aTC_material: [],//所有资料
            t_aTC_materialList: [],//资料列表
            LANG: 'US'
        }
        this.form = {
            input: '',
            radio: null,
        }
    }

    componentDidMount() {
        this.getMaterialType();
        this.getMaterial();
        var localLANG = localStorage.getItem('LANG');
        this.setState({
            LANG: localLANG ? localLANG : 'US'
        })
    }
    getMaterialType = () => {
        var that = this;
        let sql = 'select * from material_type';
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log(res.data);
                if (res.data.err) { message.warn('无法获取数据'); return }
                let f_nowLang = res.data.ret.filter(fil => fil.language === this.state.LANG);
                that.setState({
                    t_aTC_materialType: f_nowLang
                })
            })
    }
    getMaterial = () => {
        var that = this;
        let sql = 'select t1.*,t2.language from material t1 left join material_type t2 on t1.material_type = t2.id';
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log(res.data);
                if (res.data.err) { message.warn('无法获取数据'); return }
                let f_nowLang = res.data.ret.filter(fil => fil.language === this.state.LANG);
                //初始情况 资料列表最多十条
                var f_aTC_materialList = f_nowLang.length > 10 ? f_nowLang.slice(0, 10) : f_nowLang;
                that.setState({
                    t_aTC_material: f_nowLang,
                    t_aTC_materialList: f_aTC_materialList
                })
            })
    }
    treeClick = (selectKey) => {
        let typeId = selectKey[0];
        let f_data = this.state.t_aTC_material.filter(fil => fil.material_type == typeId);
        this.setState({
            t_aTC_materialList: f_data
        })
    }
    handleSearch = () => {
        if (!this.form.input) {
            message.warn('请输入搜索内容');
            return
        }
        var that = this;
        let input = this.form.input;
        let type = this.form.radio;

        let sql = 'select * from material where ';
        if (input) {
            sql += `(id='${input}' or material_name like '%${input}%')`;
        }
        if (type) {
            sql += `${input ? 'and' : ''} material_type = ${type}`;
        }
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log('搜索', sql, res.data);
                that.setState({
                    t_aTC_materialList: res.data.ret
                })
            })
    }
    render() {
        return (
            <div className="mianContainer">
                <div className="containerInner">
                    <p>{LANGUAGE[this.state.LANG].download[0]}</p>
                    <hr />
                    <div className="breadNav">
                        <p>{LANGUAGE[this.state.LANG].download[1]}</p>
                        <hr />
                        {this.state.t_aTC_materialType.length==0&&<div style={{textAlign:'center'}}><Spin /></div>}
                        <Tree onSelect={this.treeClick}>
                            {this.state.t_aTC_materialType.map(m =>
                                <TreeNode className="news-t-father" key={m.id} title={m.material_type_name} onClick={e => this.treeClick(m.id)} />
                            )}
                        </Tree>
                    </div>
                    <div className="downloadMain">
                        <div className="searchBox">
                            <input type="text" placeholder={LANGUAGE[this.state.LANG].download[2]} onChange={e => this.form.input = e.target.value} />
                            <button onClick={this.handleSearch}></button>
                        </div>
                        <br />
                        {/* 动态的获取radio */}
                        {this.state.t_aTC_materialType.map(t =>
                            <label key={t.id}><input type="radio" name="searchType" value={t.id} onClick={e => this.form.radio = e.target.value} />{" " + t.material_type_name}</label>
                        )}
                        <p className="remarks">{LANGUAGE[this.state.LANG].download[3]}<br />
                            {LANGUAGE[this.state.LANG].download[4]}<br />
                            {LANGUAGE[this.state.LANG].download[5]}
                        </p>
                        <h3>{LANGUAGE[this.state.LANG].download[6]}</h3>
                        <ul>
                            {this.state.t_aTC_materialList.map(l =>
                                // 文件列表的样式
                                <li key={l.id}>
                                    <a target="_blank" href={l.material_url} download={l.material_name}>
                                        <img src="http://joy-free.com/images/download/pdf.png" />
                                        {l.material_name}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Download