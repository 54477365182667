import React from 'react';
import '../layouts/layout.scss';
import '../layouts/phone.scss';
import qs from 'qs'
import axios from 'axios'
import { message } from 'antd'
// 轮播图
import CustomBuildSwiper from '../components/CustomBuildSwiper';
import { LANGUAGE } from '../language';
import FloatBall from '../components/FloatBall';

class Overview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            LANG: 'US',
            t_aTC_card: []
        }
        // this.cardImages = [
        //     { url: 'http://joy-free.com/images/card/card1.png' },
        //     { url: 'http://joy-free.com/images/card/card2.png' },
        //     { url: 'http://joy-free.com/images/card/card3.png' },
        //     { url: 'http://joy-free.com/images/card/card4.png' }
        // ]
    }
    componentDidMount() {
        // console.log('indexx',this.props)
        var localLANG = localStorage.getItem('LANG');
        this.setState({
            LANG: localLANG ? localLANG : 'US'
        });
        this.getMenuCard(localLANG ? localLANG : 'US');
    }
    getMenuCard = (localLANG) => {
        var that = this;
        let sql = `select * from menu_img where language='${localLANG}'`;
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log(res.data);
                if (res.data.err) { message.warn('无法获取数据'); return }
                that.setState({
                    t_aTC_card: res.data.ret,
                })
            })
    }
    render() {
        return (
            <div className="overview">
                <div className="swiperBox">
                    <CustomBuildSwiper />
                </div>
                <FloatBall />
                <div className="content">
                    <p style={{ fontSize: this.state.LANG === 'US' ? '28px' : '26px' }}>{LANGUAGE[this.state.LANG].overview[0]}</p>
                    <div className="cardBox">
                        {
                            this.state.t_aTC_card.map((img, index) => {
                                return <a key={index} onClick={e => this.props.history.push(`/main/product?typeid=${img.product_type_id}`)}>
                                    <div className="card" style={{ backgroundImage: `url(${img.url})` }}>
                                        <span>{img.title}</span>
                                    </div></a>
                            })
                        }
                    </div>
                </div>
            </div >
        );
    }
}
export default Overview