import React from 'react'
import '../layouts/layout.scss'
// SVG
import Whitelogo from '../assets/Whitelogo.svg'
//这个用来跳转的
import { Link } from 'react-router-dom'
//language
import { LANGUAGE } from '../language'
import axios from 'axios';
import qs from 'qs';
import { message } from 'antd';
class Bottom extends React.Component {
    constructor() {
        super()
        this.state = {
            LANG: 'US',//进入该页时　将全局的LANG　赋给　该页的LANG
            t_aTC_solutionType:[],
        }
    }
    componentDidMount() {
        var localLANG = localStorage.getItem('LANG');
        this.setState({
            LANG: localLANG ? localLANG : 'US'
        })
        this.getSolutionType(localLANG?localLANG:'US');
    }
    getSolutionType = (localLANG) => {
        var that = this;
        let sql = `select * from solution_type where language ='${localLANG}'`;
        axios.post('http://47.103.197.21:8081/mysql',
            qs.stringify({ 'sql': sql }))
            .then(res => {
                // console.log('搜索', sql, res.data);
                if (res.data.err) { message.warn('无法获取数据'); return }
                that.setState({
                    t_aTC_solutionType: res.data.ret,
                });
            })
    }
    render() {
        return (
            <div className="bottomContainer">
                <div className="bottomInner">
                    <img src={Whitelogo} alt="White logo" />
                    <div className="linkTable">
                        <span>{LANGUAGE[this.state.LANG].footer[0]}</span>
                        <hr />
                        {/* <ul>
                            <li><Link to="/main/solution" style={{ fontSize: this.state.LANG === 'ZH' ? '16px' : '' }}>{LANGUAGE[this.state.LANG].footer[1]}</Link></li>
                            <li><Link to="/main/solution" style={{ fontSize: this.state.LANG === 'ZH' ? '16px' : '' }}>{LANGUAGE[this.state.LANG].footer[2]}</Link></li>
                            <li><Link to="/main/solution" style={{ fontSize: this.state.LANG === 'ZH' ? '16px' : '' }}>{LANGUAGE[this.state.LANG].footer[3]}</Link></li>
                            <li><Link to="/main/solution" style={{ fontSize: this.state.LANG === 'ZH' ? '16px' : '' }}>{LANGUAGE[this.state.LANG].footer[4]}</Link></li>
                        </ul> */}
                        <ul>
                            {this.state.t_aTC_solutionType.map(s=>
                               <li key={s.id} style={{ fontSize: this.state.LANG === 'ZH' ? '16px' : '' }}>
                                   <Link to={'/main/solution?typeid='+s.id}>{s.solution_type_name}</Link>
                            </li> 
                                )}
                        </ul>
                    </div>
                    <div className="linkTable">
                        <span>{LANGUAGE[this.state.LANG].footer[5]}</span>
                        <hr />
                        <ul>
                            <li><Link to="/main/company" style={{ fontSize: this.state.LANG === 'ZH' ? '16px' : '' }}>{LANGUAGE[this.state.LANG].footer[6]}</Link></li>
                            <li><Link to="/main/news" style={{ fontSize: this.state.LANG === 'ZH' ? '16px' : '' }}>{LANGUAGE[this.state.LANG].footer[7]}</Link></li>
                            <li><Link to="/main/contacts" style={{ fontSize: this.state.LANG === 'ZH' ? '16px' : '' }}>{LANGUAGE[this.state.LANG].footer[8]}</Link></li>
                        </ul>
                    </div>
                    <div className="copyright">
                        <hr />
                        <p>{LANGUAGE[this.state.LANG].footer[9]}</p>
                    </div>
                </div>

            </div>
        )
    }
}

export default Bottom