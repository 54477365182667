import React, { Component } from 'react';
import { Map, Marker } from 'react-amap';
import qs from 'qs';
import axios from 'axios';
import { message } from 'antd';
import '../layouts/layout.scss';
import { LANGUAGE } from '../language';
import { ReactComponent as Address } from '../assets/contacts/address.svg';
import { ReactComponent as Email } from '../assets/contacts/email.svg';
import { ReactComponent as Phone } from '../assets/contacts/phone.svg';
import { ReactComponent as Telephone } from '../assets/contacts/telephone.svg';

class Contacts extends Component {
    constructor() {
        super()
        this.state = {
            t_aTC_headquarters: {},
            t_aTC_dealer: [],
            LANG: 'US'
        }
        this.Server = "http://47.103.197.21:8081/mysql"
    }
    componentDidMount() {
        var localLANG = localStorage.getItem('LANG');
        this.setState({
            LANG: localLANG ? localLANG : 'US'
        })
        this.getHeadQuarters(localLANG ? localLANG : 'US');
        this.getDealer(localLANG ? localLANG : 'US');
        // this.defaultGaodeMap();
    }
    defaultGaodeMap = () => {
        // console.log(AMap);
        // var map = new AMap.Map('gaode', {
        //     resizeEnable: true, //是否监控地图容器尺寸变化
        //     zoom:11, //初始化地图层级
        //     center: [116.397428, 39.90923] //初始化地图中心点
        // });
    }
    getHeadQuarters = (localLANG) => {
        let sql = 'select * from contacts_headquarters';
        axios.post(this.Server,
            qs.stringify({ 'sql': sql }))
            .then(res => {
                if (res.data.err) { message.warn('无数据'); return }
                // console.log('地址', sql, res.data.ret);
                let f_data_lang = res.data.ret.filter(fil => fil.language == localLANG);
                this.setState({
                    t_aTC_headquarters: f_data_lang[0]
                })
            })
    }
    getDealer = (localLANG) => {
        let sql = 'select * from contacts_headquarters';
        axios.post(this.Server,
            qs.stringify({ 'sql': sql }))
            .then(res => {
                if (res.data.err) { message.warn('无数据'); return }
                // console.log('经销商', sql, res.data.ret);
                let f_data_lang = res.data.ret.filter(fil => fil.language == localLANG);
                this.setState({
                    t_aTC_dealer: f_data_lang
                })
            })
    }
    render() {
        const { t_aTC_headquarters } = this.state
        return (
            <div className="contactStyle">
                <div className="container">
                    <p>{LANGUAGE[this.state.LANG].contacts[0]}</p>
                    <div className="map">
                        <Map center={{ lng: 121.51421, lat: 31.084973 }} zoom="15"
                            lang={this.state.LANG === 'US' ? 'en' : 'zh_cn'}
                            amapkey="6e07068a46868b870c5c89fc32feb371">
                            <Marker position={{ lng: 121.5143680573, lat: 31.0849143274 }} />
                        </Map>
                    </div>
                    <div className="info">
                        <span>{LANGUAGE[this.state.LANG].contacts[1]}</span>
                        <ul>
                            <li><Telephone />{t_aTC_headquarters.tell}</li>
                            <li><Phone />{t_aTC_headquarters.phone}</li>
                            <li><Email />{t_aTC_headquarters.email}</li>
                            <li><Address />{t_aTC_headquarters.adress}</li>
                        </ul>
                    </div>
                </div>
            </div >
        )
    }
}

export default Contacts
